<template>
  <div class="v-hall-info v-mb-md">
    <div
      v-if="phones.length > 0"
      class="v-d-flex v-flex-row v-mb-sm v-hall-info__order--phone"
    >
      <div
        v-if="isMainPage"
        class="v-caption"
        v-html="translate('restaurantList.captionPhone')"
      />
      <icon-old-general-phone-call
        v-else
        class="v-hall-info--icon v-mr-xs"
      />

      <div
        class=""
        :class="{ 'v-hall-info--after-icon': !isMainPage }"
      >
        <div
          v-for="(phone, phoneIndex) in phones"
          :key="`hall-${hall.ID}-phone-${phoneIndex}`"
          class="v-mr-xs"
        >
          <arora-nuxt-link
            class-name="v-pointer v-hall-info--phone"
            external-link
            :label="
              stringIsNullOrWhitespace(phone.Comment)
                ? phoneBeautified(sanitize(phone.PhoneNumber), 'other', true)
                : `${phoneBeautified(sanitize(phone.PhoneNumber), 'other', true)} (${phone.Comment})`
            "
            :href="`tel:+${phoneBeautified(sanitize(phone.PhoneNumber), 'other').replace(/\D+/g, '')}`"
          />
          <span
            v-if="phoneIndex < hall.Phones.length - 1"
            v-html="','"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!stringIsNullOrWhitespace(hall.WorkTime)"
      class="v-d-flex v-flex-row v-mb-sm v-cursor-default v-hall-info__order--work v-select-none"
    >
      <div
        v-if="isMainPage"
        class="v-caption"
        v-html="translate('restaurantList.captionWork')"
      />
      <icon-old-general-time
        v-else
        class="v-hall-info--icon v-mr-xs"
      />
      <div :class="{ 'v-hall-info--after-icon': !isMainPage }">
        <span v-html="sanitize(hall.WorkTime)" />
      </div>
    </div>
    <div
      v-if="!stringIsNullOrWhitespace(hall.Email)"
      class="v-d-flex v-flex-row v-hall-info__order--email"
    >
      <div
        v-if="isMainPage"
        class="v-caption"
        v-html="translate('restaurantList.captionEmail')"
      />
      <icon-old-general-mail
        v-else
        class="v-hall-info--icon v-mr-xs"
      />
      <div :class="{ 'v-hall-info--after-icon': !isMainPage }">
        <div
          v-for="(email, emailIndex) in hall.Email.split(',')"
          :key="`hall-${hall.ID}-email-${emailIndex}`"
          class="v-mr-xs"
        >
          <arora-nuxt-link
            class-name="v-pointer v-hall-info--phone"
            external-link
            :href="`mailto:${sanitize(email)}`"
            :label="sanitize(email)"
          />
          <span
            v-if="emailIndex < hall.Email.split(',').length - 1"
            v-html="','"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { RestaurantPhone } from '~types/pageStore'
import type { RestaurantsParts } from '~types/props'

import { useCommon } from '@arora/common'

import { computed } from 'vue'

const props = defineProps<
  RestaurantsParts & {
    isMainPage?: boolean
  }
>()

const { stringIsNullOrWhitespace } = useCommon()
const { sanitize, translate } = useI18nSanitized()
const { phoneBeautified } = usePhoneNumber()

const phones = computed<RestaurantPhone[]>(() =>
  props.hall.Phones.filter((item) => !stringIsNullOrWhitespace(item.PhoneNumber))
)
</script>

<style lang="scss">
@use 'assets/variables';

.v-hall-info {
  a,
  a:link,
  a:active,
  a:visited {
    color: variables.$Mono1000;
  }

  &--phone {
    text-wrap: nowrap;
  }

  &--icon {
    width: 1.1rem;
    height: 1.1rem;

    flex: 0 0 1.715rem;
    min-width: 1.1rem;
    min-height: 1.1rem;

    fill: none;
    color: variables.$Mono400;
  }

  &--after-icon {
    flex: 0 0 calc(100% - 2.25rem);
    max-width: calc(100% - 2.25rem);
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
