<template>
  <div
    class="v-d-flex v-mb-sm v-justify-content-between v-align-halls-center v-halls-card-address"
  >
    <div
      class="v-halls-card-title"
      :class="{
        'v-halls-card-title__has-link': hasLink
      }"
      v-html="sanitize(hall.Title)"
    />
    <arora-nuxt-link
      v-if="
        hasLink &&
        !stringIsNullOrWhitespace(hall.Link2) &&
        !stringIsNullOrWhitespace(hall.Link2Description)
      "
      class-name="v-btn v-btn-secondary v-text-center v-halls-card-link"
      :external-link="!hall.Link2.startsWith('/')"
      :href="hall.Link2"
      :label="clean(hall.Link2Description)"
      :open-in-new-tab="!hall.Link2.startsWith('/')"
    />
  </div>
  <div
    v-if="stringIsNullOrWhitespace(hall.AddressMapOrganisation)"
    class="v-d-flex"
  >
    <icon-old-address-mark class="v-hall-address-icon v-mr-xs" />
    <span v-html="sanitize(hall.Address)" />
  </div>
  <arora-nuxt-link
    v-else
    class-name="v-pointer v-d-flex"
    external-link
    open-in-new-tab
    :href="`https://yandex.ru/maps/org/${clean(hall.AddressMapOrganisation)}`"
    :label="sanitize(hall.Address)"
  >
    <icon-old-address-mark class="v-hall-address-icon v-mr-xs" />
    <span
      class="v-color-mono-1000"
      v-html="sanitize(hall.Address)"
    />
  </arora-nuxt-link>
</template>

<script setup lang="ts">
import type { RestaurantsParts } from '~types/props'

import { useCommon } from '@arora/common'

const { disableLink } = defineProps<RestaurantsParts & { disableLink?: boolean }>()

const hasLink = computed<boolean>(
  () =>
    !disableLink &&
    pageStore.RestaurantsSorted.some(
      (r) =>
        !stringIsNullOrWhitespace(r.Link2) &&
        !stringIsNullOrWhitespace(r.Link2Description)
    )
)

const pageStore = usePageStore()

const { stringIsNullOrWhitespace } = useCommon()
const { clean, sanitize } = useI18nSanitized()
</script>

<style lang="scss">
@use 'assets/variables';

.v-hall-address-icon {
  color: variables.$Mono400;
  width: 1.715rem;
  height: 1.1rem;

  min-width: 1.1rem;
  min-height: 1.1rem;

  fill: none;
}

.v-halls-card-title {
  font-weight: 600;
  font-size: 1.15rem;

  &__has-link {
    min-height: 2rem;
  }
}

.v-halls-card-address {
  a,
  a:link,
  a:active,
  a:visited {
    font-size: 0.85rem;
    color: variables.$SecondaryColor;
  }
}

.v-halls-card-link {
  height: 2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  width: 145px;
  margin-left: 0.5rem;

  font-size: 0.85rem;
}
</style>
